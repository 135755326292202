import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateComponent } from './pages/activate/activate.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { IndexComponent } from './pages/index/index.component';

const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'activate/:token', component: ActivateComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
