<div class="container">
    <h1>Account Aktivierung</h1>
    <div class="alert alert-primary my-2" *ngIf="loading">
        <span class="spinner-border spinner-border-sm me-1"></span>Dein Account wird aktiviert
    </div>
    <div class="alert alert-danger my-2" *ngIf="error">
        <i class="fa fa-times-circle me-1"></i>Der Account konnte nicht aktiviert werden
    </div>
    <div class="alert alert-danger my-2" *ngIf="errorUserActivated">
        <i class="fa fa-times-circle me-1"></i>Der Account wurde bereits aktiviert
    </div>
    <p *ngIf="activated">
        Hey {{user.firstName}}!<br>
        Du hast deinen Account <b>{{user.username}}</b> mit deiner E-Mail <b>{{user.email}}</b> erfolgreich aktiviert. Du kannst dich nun <a routerLink="/" fragment="login">einloggen</a>.
    </p>
</div>
