export class User {
    userId!: number;
    username!: string;
    email!: string;
    firstName!: string;
    lastName!: string;
    ageClass!: string;
    gender!: number;
    birthYear!: number;
    federation!: string;
    dtbId!: number;
    clubNumber!: number;
    clubName!: string;
    lk!: number;
    lastLk!: number;
    bestLk!: number;
    worstLk!: number;
    worstLkDate!: Date;
    token!: string;
}