<footer class="footer bg-dark text-white py-2">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <h5>Inside In</h5>
            <ul class="list-unstyled text-muted">
                <li>
                    powered by <a class="text-decoration-none" href="https://bookcourts.de" target="_blank">
                        BookCourts
                    </a>
                </li>
                <li>
                  {{ year | date:'yyyy' }} | <a class="text-decoration-none" href="https://henrikfiedler.de" target="_blank">
                    Henrik Fiedler
                </a>
                </li>
                <li>
                  v{{ version }}
                </li>
                <li>
                    <a class="text-decoration-none" routerLink="/impressum">
                        <i class="fa fa-book me-1"></i>
                        <span>Impressum & Datenschutz</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-sm-6 col-md-3">
            <h5>Kontakt</h5>
            <ul class="list-unstyled text-small">
              <li>
                <a class="text-decoration-none" href="mailto:info@inside-in.de">
                  <i class="fa fa-envelope me-1"></i>
                  <span>Kontakt</span>
                </a>
              </li>
            </ul>
        </div>
        <div class="col-sm-6 col-md-3">
            <h5>Social Media</h5>
            <ul class="list-unstyled text-small">
              <li>
                <a class="text-decoration-none" href="https://www.youtube.com/channel/UCs-wRAp0d2_ziv4Vcm8kkaA"
                  target="_blank">
                  <i class="fab fa-youtube me-1"></i>YouTube
                </a>
              </li>
              <li>
                <a class="text-decoration-none" href="https://twitter.com/bookcourtsde" target="_blank">
                  <i class="fab fa-twitter me-1"></i>Twitter
                </a>
              </li>
              <li>
                <a class="text-decoration-none" href="https://instagram.com/bookcourtsde" target="_blank">
                  <i class="fab fa-instagram me-1"></i>Instagram
                </a>
              </li>
            </ul>
        </div>
        <div class="col-sm-6 col-md-3">
          <h5>Einstellungen</h5>
          <ul class="list-unstyled text-small">
            <li class="">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="useSystemDarkModeSwitch" (change)="changeSystemDarkMode($event)"
                  [checked]="useSystemDarkMode" [disabled]="availableSystemDarkMode === false">
                <label class="form-check-label" for="useSystemDarkModeSwitch">
                  System-<i class='fa fa-moon'></i>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
