import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  activationToken: string | null = '';
  loading: boolean = false;
  user = new User;
  activated: boolean = false;
  error: boolean = false;
  errorUserActivated: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authservice: AuthService
  ) {
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.activationToken = params.get('token');
        this.activateUser();
      }
    );
  }

  activateUser(): void {
    this.loading = true;
    this.authservice.activate(this.activationToken).subscribe(
      (res: any) => {
        this.loading = false;
        this.error = false;
        this.errorUserActivated = false;
        this.activated = true;
        console.log(res);
        this.user = res.body;
        console.log(this.user);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        if (err.status === 400) {
          this.errorUserActivated = true;
        } else if (err.status === 500) {
          this.error = true;
        }
      }
    );
  }

}
