<header class="fixed-top">
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand" routerLink="/">
        <img src="../../../assets/images/logo/logo.png" width="auto" height="30" class="d-inline-block align-to rounded"
          alt="" loading="lazy">
      </a>
      <ul class="navbar-nav ms-auto">
        <li class="navbar-text" *ngIf="user">
          <i class="fa me-1" [ngClass]="{
            'fa-male': user.gender == 1,
            'fa-female': user.gender == 0
          }"></i>{{ user.username }}
        </li>
        <li class="nav-item">
          <div class="nav-link">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="darkSwitch" (change)="changeTheme($event)"
                [checked]="darkTheme">
              <label class="form-check-label" for="darkSwitch">
                <i class='fa fa-moon'></i>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
