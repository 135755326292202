import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  searchUser(federation: string, firstName: string, lastName: string, id: string): any {
    const params = new HttpParams()
      .set('federation', federation)
      .set('firstName', firstName)
      .set('lastName', lastName)
      .set('id', id);
    return this.http.get<any>(`${environment.baseUrl}/users/search`, { params, observe: 'response' });
  }

  getLk(federation: string, clubNumber: number, dtbId: number, gender: number): any {
    const params = new HttpParams()
      .set('federation', federation)
      .set('clubNumber', clubNumber)
      .set('dtbId', dtbId)
      .set('gender', gender);
    return this.http.get<any>(`${environment.baseUrl}/users/lk`, { params, observe: 'response' });
  }

  getProfile(): any {
    return this.http.get<any>(`${environment.baseUrl}/users/my`, { observe: 'response' });
  }

  getLks(): any {
    return this.http.get<any>(`${environment.baseUrl}/users/lks`, { observe: 'response' });
  }
}
