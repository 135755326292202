import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject = new BehaviorSubject<User | null>(null);
  public user = this.userSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  login(user: any): any {
    return this.http.post<any>(`${environment.baseUrl}/users/login`, user, { observe: 'response' });
  }

  refresh(): any {
    return this.http.post<any>(`${environment.baseUrl}/users/refresh`, null, { observe: 'response' });
  }

  setAuth(token: string): any {
    const payload = JSON.parse(atob(token.split('.')[1]));

    const user = new User();

    user.userId = payload.userId;
    user.username = payload.username;
    user.gender = payload.gender;
    user.token = token;

    this.userSubject.next(user);
  }

  logout(all?: boolean): any {
    const body = all ? {all} : null;
    this.userSubject.next(null);
    
    return this.http.post<any>(`${environment.baseUrl}/users/logout`, body, { observe: 'response' });
  }

  checkUsername(username: string): any {
    const params = new HttpParams()
      .set('username', username);
    return this.http.get<any>(`${environment.baseUrl}/users/check`, { params, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
  }

  register(user: any): any {
    return this.http.post<any>(`${environment.baseUrl}/users`, user, { observe: 'response' });
  }

  sendActivationMail(userId: number): any {
    return this.http.post<any>(`${environment.baseUrl}/users/send-activation-mail`, { userId }, { observe: 'response' });
  }

  activate(token: string | null): any {
    return this.http.post<any>(`${environment.baseUrl}/users/activate`, { token }, { observe: 'response' });
  }
}
