import { Component, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Observable, timer, Subscription } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
/* import { AuthService } from 'src/app/services/auth.service'; */
/* import { Auth } from 'src/app/models/auth'; */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User | null = new User;

  navbarOpen: boolean;

  theme = '';
  darkTheme = false;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private authservice: AuthService
  ) {
    // Auth Subscription
    this.authservice.user.subscribe(
      user => {
        this.user = user;
      }
    );

    // Initial Token Refresh
    this.authservice.refresh().subscribe(
      (res: any) => {
        this.authservice.setAuth(res.body.accessToken);
      }
    );

    // Close Navbar
    this.navbarOpen = false;

    // Dark Mode Available Detection
    if (localStorage.getItem('useSystemDarkMode') !== 'false' && window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      /* Dark Mode Initial Detection */
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }
      this.setTheme(this.theme);

      // Dark Mode Change Detection
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        this.theme = e.matches ? 'dark' : 'light';
        this.setTheme(this.theme);
      });
    } else {
      if (localStorage.getItem('theme') === 'dark') {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }
      this.setTheme(this.theme);
    }

  }

  ngOnInit(): void {

  }

  changeTheme(event: any): void {
    if (localStorage.getItem('useSystemDarkMode') !== 'false') {
      alert('Deine System-Dark-Mode-Einstellung wird nur für diese Sitzung überschrieben.');
    }
    if (event.target.checked === true) {
      this.theme = 'dark';
    } else {
      this.theme = 'light';
    }
    this.setTheme(this.theme);
  }

  setTheme(theme: string): void {
    localStorage.setItem('theme', theme);
    this.renderer.setAttribute(document.body, 'data-theme', theme);
    if (theme === 'dark') {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  }


  /* toggleNavbar(close?: boolean): void {
    if (close) {
      this.navbarOpen = false;
    } else {
      this.navbarOpen = !this.navbarOpen;
    }
  } */

  /* logout(): void {
    this.authservice.logout().subscribe(
      res => {
        this.router.navigateByUrl('/');
      },
      err => {
        alert('Logout nicht möglich');
      }
    );
  } */

}
