<div class="container-fluid background-light py-5">
  <div class="container">
    <h1>Inside In</h1>
    <hr>
    <p class="lead">
      Dein Tennismanager zur LK 2.0. Lass deine LK automatisch tracken und nutze den LK-Rechner.
    </p>
    <p>
      <button class="btn btn-outline-primary mb-1 me-1" data-bs-toggle="modal" data-bs-target="#login" *ngIf="!user">
        Login
      </button>
      <button class="btn btn-primary mb-1 me-1" data-bs-toggle="modal" data-bs-target="#register" *ngIf="!user">
        Registrieren
      </button>
      <button class="btn btn-outline-danger mb-1 me-1" data-bs-toggle="modal" data-bs-target="#logout" *ngIf="user">
        Logout
      </button>
    </p>
  </div>
</div>
<div class="container my-4">
  <div class="row g-3">
    <div class="col-12" *ngIf="user">
      <button class="btn btn-link text-decoration-none" (click)="initialize()">
        <i class="fa fa-sync me-1"></i>Aktualisieren
      </button>
    </div>
    <div class="col-md-6" id="profile" *ngIf="user">
      <div class="card shadow">
        <div class="card-header">
          <h2 class="card-title">
            Profil
          </h2>
        </div>
        <div class="card-body">
          <h5>
            <i class="fa me-1" [ngClass]="{
              'fa-male': user.gender == 1,
              'fa-female': user.gender == 0
            }"></i>{{ user.username }}
          </h5>
          <p>
            {{ user.firstName }} {{ user.lastName }} ({{ user.birthYear }})
            <br>
            {{ user.clubName }} ({{ user.clubNumber }} | {{ user.federation }})
          </p>
          <h5>
            LK{{ user.lk | number:'1.1-1' }} <span *ngIf="(user.lk - user.lastLk) < 0"><i
                class="fa fa-angle-up text-success me-1"></i>({{(user.lk - user.lastLk) | number:'1.1-1' }})</span>
            <span *ngIf="(user.lk - user.lastLk) > 0"><i
                class="fa fa-angle-down text-danger me-1"></i>(+{{(user.lk - user.lastLk) | number:'1.1-1' }})</span>
            <span *ngIf="(user.lk - user.lastLk) == 0"><i class="fa fa-angle-right text-secondary"></i></span>

          </h5>
          <p>
            <i class="fa fa-thumbs-up me-1"></i>LK{{ user.bestLk | number:'1.1-1' }}
            <br>
            <i class="fa fa-thumbs-down me-1"></i>LK{{ user.worstLk | number:'1.1-1' }}
          </p>
        </div>
      </div>
    </div>
    <!-- Dashboard -->
    <div class="col-md-6" id="dashboard" [ngClass]="{'d-none': !user}">
      <div class="card shadow">
        <div class="card-header">
          <h2 class="card-title">
            Dashboard
          </h2>
        </div>
        <div class="card-body">
          <div class="row g-2">
            <div class="col-12">
              <canvas id="chart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LK Rechner -->
    <div class="col-md-6" id="calculator">
      <div class="card shadow">
        <div class="card-header">
          <h2 class="card-title">
            LK-Rechner
          </h2>
        </div>
        <div class="card-body">
          <form [formGroup]="calcForm" (ngSubmit)="submitCalcForm()">
            <div class="input-group"
              [ngClass]="{'has-validation': calcForm.controls.myLk.invalid && calcForm.controls.myLk.dirty}">
              <div class="input-group-text">
                Meine LK
              </div>
              <input class="form-control" type="number" formControlName="myLk" step="0.1" min="1,0" max="25,0"
                placeholder="18,7" [ngClass]="{
              'is-invalid': calcForm.controls.myLk.invalid && (calcForm.controls.myLk.dirty || calcFormSubmitted),
              'is-valid': calcForm.controls.myLk.valid && calcForm.controls.myLk.touched
            }">
              <button type="button" class="btn btn-outline-primary" *ngIf="calcForm.controls.myLk.value !== null"
                (click)="resetMyLk()">
                <i class="fa fa-redo"></i>
              </button>
              <div class="invalid-feedback"
                *ngIf="calcForm.controls.myLk.invalid && (calcForm.controls.myLk.dirty || calcFormSubmitted)">
                LK1,0-LK25,0!
              </div>
            </div>
            <hr>
            <div formArrayName="matches">
              <div class="mb-2" *ngFor="let match of matches.controls; let i=index" [formGroupName]="i">
                <div class="card">
                  <div class="card-header">
                    <h5 class="card-title">
                      Sieg {{i+1}}
                      <button class="btn btn-sm btn-danger float-end" (click)="removeMatch(i)" *ngIf="i > 0">
                        <i class="fa fa-times"></i>
                      </button>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                          <input type="radio" class="btn-check" id="{{'singles'+i}}" formControlName="matchType"
                            value="singles" autocomplete="off" (change)="switchMatchType(i)" checked>
                          <label class="btn btn-outline-primary" for="{{'singles'+i}}">Einzel</label>

                          <input type="radio" class="btn-check" id="{{'doubles'+i}}" formControlName="matchType"
                            value="doubles" autocomplete="off" (change)="switchMatchType(i)">
                          <label class="btn btn-outline-primary" for="{{'doubles'+i}}">Doppel</label>
                        </div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <div class="input-group" *ngIf="match.controls.matchType.value !== 'doubles'"
                          [ngClass]="{'has-validation': match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted)}">
                          <div class="input-group-text">
                            Gegner
                          </div>
                          <input class="form-control" type="number" formControlName="opponentLk1" step="0.1" min="1,0"
                            max="25,0" placeholder="17,2" [ngClass]="{
                          'is-invalid': match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted),
                          'is-valid': match.controls.opponentLk1.valid && match.controls.opponentLk1.touched
                        }">
                          <div class="invalid-feedback"
                            *ngIf="match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted)">
                            LK1,0-LK25,0!
                          </div>
                        </div>
                        <div class="input-group" *ngIf="match.controls.matchType.value === 'doubles'"
                          [ngClass]="{'has-validation': match.controls.partnerLk.invalid && (match.controls.partnerLk.dirty || calcFormSubmitted)}">
                          <div class="input-group-text">
                            Partner
                          </div>
                          <input class="form-control" type="number" formControlName="partnerLk" step="0.1" min="1,0"
                            max="25,0" placeholder="16,9" [ngClass]="{
                          'is-invalid': match.controls.partnerLk.invalid && (match.controls.partnerLk.dirty || calcFormSubmitted),
                          'is-valid': match.controls.partnerLk.valid && match.controls.partnerLk.touched
                        }">
                          <div class="invalid-feedback"
                            *ngIf="match.controls.partnerLk.invalid && (match.controls.partnerLk.dirty || calcFormSubmitted)">
                            LK1,0-LK25,0!
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <div class="input-group"
                          [ngClass]="{'has-validation': match.controls.ageClass.invalid && (match.controls.ageClass.dirty || calcFormSubmitted)}">
                          <div class="input-group-text">
                            AK
                          </div>
                          <select class="form-select" formControlName="ageClass" [ngClass]="{
                        'is-invalid': match.controls.ageClass.invalid && (match.controls.ageClass.dirty || calcFormSubmitted),
                        'is-valid': match.controls.ageClass.valid && match.controls.ageClass.touched
                      }">
                            <option value="null" selected disabled>Auswählen</option>
                            <option [value]="ageClass.value" *ngFor="let ageClass of ageClasses; let i = index">
                              {{ageClass.name}}
                            </option>
                          </select>
                          <div class="invalid-feedback"
                            *ngIf="match.controls.ageClass.invalid && (match.controls.ageClass.dirty || calcFormSubmitted)">
                            Altersklasse auswählen!
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-2" *ngIf="match.controls.matchType.value === 'doubles'">
                        <div class="input-group"
                          [ngClass]="{'has-validation': (match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted)) || (match.controls.opponentLk2.invalid && (match.controls.opponentLk2.dirty || calcFormSubmitted))}">
                          <div class="input-group-text">
                            Gegner
                          </div>
                          <input class="form-control" type="number" formControlName="opponentLk1" step="0.1" min="1,0"
                            max="25,0" placeholder="17,2" [ngClass]="{
                          'is-invalid': match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted),
                          'is-valid': match.controls.opponentLk1.valid && match.controls.opponentLk1.touched
                        }">
                          <input class="form-control" type="number" formControlName="opponentLk2" step="0.1" min="1,0"
                            max="25,0" placeholder="21,5" [ngClass]="{
                          'is-invalid': match.controls.opponentLk2.invalid && (match.controls.opponentLk2.dirty || calcFormSubmitted),
                          'is-valid': match.controls.opponentLk2.valid && match.controls.opponentLk2.touched
                        }">
                          <div class="invalid-feedback"
                            *ngIf="(match.controls.opponentLk1.invalid && (match.controls.opponentLk1.dirty || calcFormSubmitted)) || (match.controls.opponentLk2.invalid && (match.controls.opponentLk2.dirty || calcFormSubmitted))">
                            LK1,0-LK25,0!
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="{{'teamGame'+i}}"
                            formControlName="teamGame">
                          <label class="form-check-label" for="{{'teamGame'+i}}">Mannschaftsspiel (+10%)</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-center my-3">
              <button type="button" class="btn btn-outline-success" (click)="addMatch()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="row">
              <div class="col-4">
                <button type="button" class="btn btn-outline-danger w-100" (click)="resetCalcForm()">
                  <i class="fa fa-redo"></i>
                </button>
              </div>
              <div class="col-8">
                <button type="submit" class="btn btn-success w-100">
                  <i class="fa fa-check"></i>
                </button>
              </div>
            </div>
            <div class="card mt-3" *ngIf="resultCalculated">
              <div class="card-header">
                <h5 class="card-title">
                  Ergebnis
                </h5>
              </div>
              <div class="card-body">
                <h5>
                  <span class="text-muted me-1">LK{{ result.currentLk | number:'1.1-1' }}</span>
                  <i class="fa fa-arrow-right me-1"></i>
                  <span class="fw-bold">LK{{ result.newLk | number:'1.1-1' }}</span>
                </h5>
                <h6>
                  LK-Begleitwert: {{ result.sideLk | number:'1.3-3' }}
                </h6>
                <h6>
                  Verbesserung: -{{ result.totalImprovement | number:'1.3-3' }}
                </h6>
                <ul class="list-group list-group-flush" *ngIf="result.matches && result.matches.length > 1">
                  <li class="list-group-item" *ngFor="let match of result.matches; let i = index">
                    -{{ match.improvement | number:'1.3-3' }}
                    <i class="fa fa-user me-2" *ngIf="match.matchType === 'singles' "></i>
                    <i class="fa fa-user-friends me-2" *ngIf="match.matchType === 'doubles' "></i>
                    <i class="fa fa-trophy" *ngIf="match.teamGame === true "></i>
                  </li>
                </ul>
              </div>
              <div class="card-footer small">
                <i class="fa fa-info-circle me-1"></i>Das exakte Ergebnis kann nur berechnet werden, wenn dein genauer
                aktueller LK-Begleitwert (3 Dezimalstellen) angegeben wird. Ansonsten kann es zu geringen Abweichungen
                (max. 0,1) kommen.
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Siegtabelle -->
    <div class="col-md-6" id="win-table">
      <div class="card shadow">
        <div class="card-header">
          <h2 class="card-title">
            Siegtabelle
          </h2>
          <form [formGroup]="winTableForm" (ngSubmit)="submitWinTableForm()">
            <div class="row g-3">
              <div class="col-12 col-lg-6">
                <div class="input-group"
                  [ngClass]="{'has-validation': winTableForm.controls.myLk.invalid && winTableForm.controls.myLk.dirty}">
                  <div class="input-group-text">
                    LK
                  </div>
                  <input class="form-control" type="number" step="0.1" min="1,0" max="25,0" placeholder="18,7"
                    formControlName="myLk" (input)="submitWinTableForm()" [ngClass]="{
                    'is-invalid': winTableForm.controls.myLk.invalid && winTableForm.controls.myLk.dirty,
                    'is-valid': winTableForm.controls.myLk.valid && winTableForm.controls.myLk.touched
                  }">
                  <button type="button" class="btn btn-outline-primary" (click)="resetWinTableLk()"
                    *ngIf="winTableForm.controls.myLk.value !== null">
                    <i class="fa fa-redo"></i>
                  </button>
                  <div class="invalid-feedback"
                    *ngIf="winTableForm.controls.myLk.invalid && winTableForm.controls.myLk.dirty">
                    LK1,0-LK25,0!
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group"
                  [ngClass]="{'has-validation': winTableForm.controls.ageClass.invalid && winTableForm.controls.ageClass.touched}">
                  <div class="input-group-text">
                    AK
                  </div>
                  <select class="form-select" formControlName="ageClass" (change)="submitWinTableForm()" [ngClass]="{
                    'is-invalid': winTableForm.controls.ageClass.invalid && winTableForm.controls.ageClass.touched,
                    'is-valid': winTableForm.controls.ageClass.valid && winTableForm.controls.ageClass.touched
                  }">
                    <option value="null" selected disabled>Auswählen</option>
                    <option [value]="ageClass.value" *ngFor="let ageClass of ageClasses; let i = index">
                      {{ageClass.name}}
                    </option>
                  </select>
                  <div class="invalid-feedback"
                    *ngIf="winTableForm.controls.ageClass.invalid && winTableForm.controls.ageClass.touched">
                    Altersklasse auswählen!
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="teamGame" formControlName="teamGame"
                    (change)="submitWinTableForm()">
                  <label class="form-check-label" for="teamGame">Mannschaftsspiel (+10%)</label>
                </div>
              </div>
              <div class="col-12 col-md-5" *ngIf="winTableCalculated">
                Hürde: <span class="fw-bold">{{ winTable.hurdle | number:'1.0-2' }}</span>
              </div>
              <div class="col-12 col-md-7" *ngIf="winTableCalculated">
                Altersfaktor: <span class="fw-bold">{{ winTable.ageClassFactor | number:'1.0-2' }}</span>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <span *ngIf="!winTableCalculated">
            Bitte LK und Altersklasse auswählen!
          </span>
          <div class="table-responsive" *ngIf="winTableCalculated">
            <table class="table table-striped table-hover">
              <thead class="table-dark">
                <tr>
                  <th>Gegner</th>
                  <th>Verbesserung</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let opponent of winTable.opponents">
                  <td>
                    LK{{ opponent.opponentLk | number:'1.1-1' }}
                  </td>
                  <td>
                    <span>
                      -{{ opponent.improvement | number:'1.3-3' }}
                    </span>
                    <span class="text-nowrap">
                      <i class="fa fa-arrow-right me-1"></i>
                      <span class="fw-bold">LK{{ opponent.newLk | number:'1.1-1' }}</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid background-light py-5 mb-0">
  <div class="container">
    <div class="row">
      <div class="col-12" id="faq">
        <h1>FAQ</h1>
        <div class="accordion" id="accordionFaq">
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqLkNew">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqLkNew" aria-expanded="false" aria-controls="FaqLkNew">
                Was hat sich an der neuen LK geändert?
              </button>
            </h2>
            <div id="FaqLkNew" class="accordion-collapse collapse" aria-labelledby="headFaqLkNew"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Die neue LK wird mit einer Dezimalstelle ausgewiesen. Außerdem wurde die LK
                um 2 Stufen erweitert. Daher gilt die neue LK von <b>1,0 bis 25,0</b>.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqSideLk">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqSideLk" aria-expanded="false" aria-controls="FaqSideLk">
                Was ist der LK-Begleitwert?
              </button>
            </h2>
            <div id="FaqSideLk" class="accordion-collapse collapse" aria-labelledby="headFaqSideLk"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Der LK-Begleitwert ist der laufende <b>genaue Wert</b> neben deiner
                eigentlichen LK. Der LK-Begleitwert wird mit
                3 Dezimalstellen ausgewiesen. Die Verbesserung oder Verschlechterung wird
                mit deinem LK-Begleitwert verrechnet.
                Zur Ermittlung deiner LK werden die <b>letzten 2 Dezimalstellen
                  abgeschnitten</b>,
                nicht
                gerundet.<br>
                <span class="fst-italic">Beispiel: </span> <span class="text-nowrap">LK-Begleitwert:
                  <b>19,395</b></span>
                &raquo;
                <span class="text-nowrap">LK: <b>19,3</b></span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqCalcLK">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqCalcLK" aria-expanded="false" aria-controls="FaqCalcLK">
                Wie wird die neue LK berechnet?
              </button>
            </h2>
            <div id="FaqCalcLK" class="accordion-collapse collapse" aria-labelledby="headFaqCalcLK"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                <p>
                  Die neue LK wird nach folgender Formel berechnet:<br>
                  <var>
                    <b>
                      Verbesserung (V) = <span class="text-nowrap">(Punkte (P) / Hürde
                        (H))</span>
                      * <span class="text-nowrap">Altersfaktor (A)</span>
                    </b>
                  </var>
                </p>
                <h5>Punkte (P)</h5>
                <p>
                  Die <b>Punkte</b> werden aus einer <a
                    href="https://www.dtb-tennis.de/var/ezdemo_site/storage/images/media/images/grafiken-lk-reform_punkte/239077-2-ger-DE/Grafiken-LK-Reform_Punkte.jpg"
                    target="_blank">Sattelfunktion</a>
                  abgelesen. Für Siege gegen mind. <b>4 LK schlechter</b> gibt es <b>10
                    Punkte</b>,
                  für Siege gegen mind. <b>4 LK besser</b> gibt es <b>110 Punkte</b>.
                  Unverändert
                  bleiben <b>50 Punkte</b> für Siege gegen die <b>gleiche LK</b>.
                </p>
                <h5>Hürde (H)</h5>
                <p>
                  Die <b>Hürde</b> wird aus einer <a
                    href="https://www.dtb-tennis.de/var/ezdemo_site/storage/images/media/images/20-lk-reform/grafiken-lk-reform_huerde/239084-1-ger-DE/Grafiken-LK-Reform_Huerde.jpg"
                    target="_blank">Exponentialfunktion</a> abgelesen. Sie beginnt bei
                  <b>LK
                    25,0</b> mit <b>50</b>. Dadurch
                  reicht ein beliebiger Sieg um in LK 24,0 aufzusteigen. Bis zu einem
                  weiteren Eckwert von <b>200</b> bei
                  <b>LK 13,0</b> ist die Funktion fast geradlinig und steigt dann zu den
                  höchsten LK-Stufen mit einer Hürde von
                  <b>über 1000</b> schnell an.
                </p>
                <h5>Altersfaktor (A)</h5>
                <p>
                  Der <b>Altersfaktor</b> wird mit dem Quotient aus Punkte/Hürde
                  verrechnet und sorgt dafür, dass
                  die LK auch im Querschnitt durch die verschiedenen Altersschichten
                  vergleichbar und die Spielstärke bei
                  altersübergreifenden Matches aussagekräftig bleibt. Er ist in der
                  Altersklasse
                  <b>U11</b> bei <b>0,2</b> und
                  steigt an, bis er in der <b>offenen Altersklasse</b> der Aktiven bei
                  <b>1</b>
                  liegt, so dass die Verbesserung
                  komplett verrechnet wird. Ab der Altersklasse <b>Ü30</b> sinkt der
                  Altersfaktor dann wieder linear bis zu
                  <b>0,3</b> in der Altersklasse <b>Ü90</b>.
                </p>
                <h5>Beispielrechnung</h5>
                <p>
                  Meine LK: <b>LK 13,0</b><br>
                  Mein LK-Begleitwert: <b>13,085</b><br>
                  Gegner-LK: <b>LK 11,0</b><br>
                  Altersklasse: <b>Ü40</b>
                </p>
                <p>
                  Punkte für Sieg gegen 2,0 LK besser: <b>80</b><br>
                  Hürde für LK 13,0: <b>200</b><br>
                  Altersfaktor für AK Ü40: <b>0,8</b>
                </p>
                <p>
                  <b>Verbesserung</b> = <b>(80 / 200) * 0,8</b><br>
                  <b>Verbesserung</b> = <b><u>0,32</u></b><br>
                  <b>Neuer LK-Begleitwert</b> = <b>13,085 - 0,32</b><br>
                  <b>Neuer LK-Begleitwert</b> = <b><u>12,765</u></b><br>
                  <b>Neue LK</b> = <b><u>12,7</u></b>
                </p>
                <p class="text-muted">
                  Quelle: <a href="https://dtb-tennis.de" target="_blank">DTB</a>
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqDoubles">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqDoubles" aria-expanded="false" aria-controls="FaqDoubles">
                Wie viel zählen Doppel/Mixed?
              </button>
            </h2>
            <div id="FaqDoubles" class="accordion-collapse collapse" aria-labelledby="headFaqDoubles"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Doppel und Mixed werden mit jeweils 50% berechnet. Die Punkte werden auf
                Basis des Mittelwerts der LKs der beiden Doppelpaare bestimmt.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqUpdate">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqUpdate" aria-expanded="false" aria-controls="FaqUpdate">
                Wie oft wird die LK aktualisiert?
              </button>
            </h2>
            <div id="FaqUpdate" class="accordion-collapse collapse" aria-labelledby="headFaqUpdate"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Die LK wird nicht mehr einmal im Jahr, sondern <b>einmal wöchentlich</b>, jeweils
                <b>mittwochs</b> aktualisiert. Dort gehen die Ergebnisse der vergangenen
                Kalenderwoche (Montag-Sonntag) ein.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqWorsen">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqWorsen" aria-expanded="false" aria-controls="FaqWorsen">
                Kann sich die LK verschlechtern?
              </button>
            </h2>

            <div id="FaqWorsen" class="accordion-collapse collapse" aria-labelledby="headFaqWorsen"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Durch <b>Niederlagen</b> kann sich die LK weiterhin <b>nicht
                verschlechtern</b>.
                Einen Abstieg am Saisonende aufgrund zu wenig erreichter Punkte oder nicht erfüllter Siege gibt es nicht
                mehr.<br>
                Dafür werden jedem Spieler, egal ob er gespielt oder nicht gespielt hat,
                gewonnen oder verloren hat, <b>jeden Monat</b> ein Motivationsaufschlag von <b>+0,1</b>
                auf seine LK drauf gerechnet. Wird ein Sieg erzielt, wird die Verbesserung direkt mit dem
                Motivationsaufschlag verrechnet.<br>
                <i>Beispiel:</i> Ist der Sieg im obigen Beispiel der erste im Monat, wird
                die
                Verbesserung von <b>0,32</b> direkt mit dem Motivationsaufschlag von 0,1
                verrechnet.
                Dadurch beträgt die Verbesserung für diesen Sieg nur <b>0,22</b>. Alle
                weiteren Siege werden aber nun regulär bewertet, da der Motivationsaufschlag für diesen
                Monat schon komplett verrechnet wurde.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqBoni">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqBoni" aria-expanded="false" aria-controls="FaqBoni">
                Gibt es Boni?
              </button>
            </h2>
            <div id="FaqBoni" class="accordion-collapse collapse" aria-labelledby="headFaqBoni"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Bei in Mannschaftsspielen (Verbandsrunde) erzielten Siegen gibt es einen
                <b>Aufschlag von 10%</b> auf die Verbesserung. Für die Teilnahme an
                Deutschen, Verbands- und Bezirksmeisterschaften gibt es zusätzlich eine Verbesserung von
                <b>0,1</b>.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqLineUp">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqLineUp" aria-expanded="false" aria-controls="FaqLineUp">
                Kann sich die Mannschaftsaufstellung während der Verbandsrunde ändern?
              </button>
            </h2>
            <div id="FaqLineUp" class="accordion-collapse collapse" aria-labelledby="headFaqLineUp"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Nein, die Mannschaftsaufstellung wird auf Basis eines <b>Stichtags
                festgeschrieben</b>.
                Dadurch kann es im Laufe der Saison vorkommen, dass ein Spieler hinter einem Spieler mit einer
                schlechteren LK aufgestellt wird.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqDtbInfo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqDtbInfo" aria-expanded="false" aria-controls="FaqDtbInfo">
                Wo finde ich weitere Informationen zur neuen LK?
              </button>
            </h2>
            <div id="FaqDtbInfo" class="accordion-collapse collapse" aria-labelledby="headFaqDtbInfo"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Weitere Informationen zur <a
                  href="https://www.dtb-tennis.de/Tennis-National/Leistungsklassen/LK-2.0-Das-neue-Leistungsklassensystem"
                  target="_blank">neuen LK</a> findest auf der Seite des <a href="https://dtb-tennis.de"
                  target="_blank">Deutschen Tennisbunds</a>.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="mb-0 accordion-header" id="headFaqMoreQuestions">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#FaqMoreQuestions" aria-expanded="false" aria-controls="FaqMoreQuestions">
                Wo kann ich mich melden, wenn ich noch Fragen habe?
              </button>
            </h2>
            <div id="FaqMoreQuestions" class="accordion-collapse collapse" aria-labelledby="headFaqMoreQuestions"
              data-parent="#accordionFaq">
              <div class="accordion-body">
                Bei Fragen oder FAQ-Vorschlägen, schreibe gerne eine <a
                  href="mailto:info@inside-in.de?subject=LK4You FAQ">E-Mail</a> an <a
                  href="mailto:info@inside-in.de?subject=LK4You FAQ">info@inside-in.de</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Login Modal -->
<div class="modal fade" id="login" tabindex="-1" aria-labelledby="login" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()" *ngIf="!user">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating">
                <input type="text" class="form-control" id="loginUsername" placeholder="username"
                  formControlName="username" [ngClass]="{
                    'is-valid': loginPasswordError && (!loginUserError || !loginFormError) ,
                    'is-invalid': loginUserError || loginFormError || loginActivationError
                }">
                <label for="loginUsername">Benutzername</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating">
                <input type="password" class="form-control" id="loginPassword" placeholder="Passwort"
                  formControlName="password" [ngClass]="{
                    'is-invalid': loginPasswordError || loginFormError || loginActivationError
                }">
                <label for="loginPassword">Passwort</label>
              </div>
            </div>
            <div class="col-12">
              <a routerLink="/password" class="btn btn-link disabled">
                Passwort vergessen? (kommt bald)
              </a>
            </div>
            <div class="col-12">
              <div class="alert alert-danger my-2" *ngIf="loginError">
                <i class="fa fa-times-circle me-1"></i>Server-Fehler. Login fehlgeschlagen
              </div>
              <div class="alert alert-danger my-2" *ngIf="loginFormError">
                <i class="fa fa-times-circle me-1"></i>Kein Benutzername oder Passwort eingegeben
              </div>
              <div class="alert alert-danger my-2" *ngIf="loginUserError">
                <i class="fa fa-times-circle me-1"></i>Benutzername nicht gefunden
              </div>
              <div class="alert alert-danger my-2" *ngIf="loginPasswordError">
                <i class="fa fa-times-circle me-1"></i>Falsches Passwort
              </div>
              <div class="alert alert-danger my-2" *ngIf="loginActivationError">
                <i class="fa fa-times-circle me-1"></i>Account nicht aktiviert. Überprüfe deine E-Mails
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-lg btn-primary w-100">
                Login
              </button>
            </div>
          </div>
        </form>
        <div class="row g-2" *ngIf="user">
          <div class="col-12">
            <p>
              Hey {{ user?.firstName }}!
            </p>
            <button class="btn btn-outline-primary" data-bs-dismiss="modal">
              Zum Profil
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <i class="fa fa-times-circle me-1"></i>Schließen
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Logout Modal -->
<div class="modal fade" id="logout" tabindex="-1" aria-labelledby="logout" aria-hidden="true" *ngIf="user">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Logout</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <button class="btn btn-danger me-1" (click)="logout()" data-bs-dismiss="modal">
            von diesem Gerät
          </button>
          <button class="btn btn-outline-danger ms-1" (click)="logout(true)" data-bs-dismiss="modal">
            von allen Geräten
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <i class="fa fa-times-circle me-1"></i>Schließen
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Register Modal -->
<div class="modal fade" id="register" tabindex="-1" aria-labelledby="register" aria-hidden="true" *ngIf="!user">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Registrieren</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="registerForm" (ngSubmit)="submitRegisterForm()" *ngIf="!registered">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating">
                <input type="email" class="form-control" id="registerEmail" placeholder="max@muster.de"
                  formControlName="email" [ngClass]="{ 
                    'is-invalid': registerForm.controls.email.invalid && (registerForm.controls.email.touched || registerFormSubmitted),
                    'is-valid': registerForm.controls.email.valid && registerForm.controls.email.touched
                }">
                <label for="registerEmail">E-Mail</label>
                <div class="invalid-feedback" *ngIf="registerForm.controls.email.errors">
                  E-Mail-Adresse angeben!
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-floating">
                <input type="text" class="form-control" id="registerUsername" placeholder="username"
                  formControlName="username" [ngClass]="{ 
                    'is-invalid': registerForm.controls.username.invalid && (registerForm.controls.username.dirty || registerFormSubmitted),
                    'is-valid': registerForm.controls.username.valid
                }">
                <label for="registerUsername">Benutzername</label>
                <div class="form-text"
                  *ngIf="registerForm.controls.username.pristine && (!usernameSuggest || registerForm.controls.email.invalid)">
                  z.B. max.muster
                </div>
                <div class="form-text"
                  *ngIf="registerForm.controls.username.pristine && (usernameSuggest && registerForm.controls.email.valid)">
                  z.B. {{ usernameSuggest }}
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.required">
                  Benutzername angeben!
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.minlength">
                  min. 5 Zeichen
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.maxlength">
                  max. 20 Zeichen
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.pattern">
                  Erlaubte Zeichen: a-z, 0-9, ".", "_"
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.userNameTaken">
                  Benutzername bereits vergeben!
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-floating">
                <select class="form-select" formControlName="ageClass" [ngClass]="{
                  'is-invalid': registerForm.controls.ageClass.invalid && (registerForm.controls.ageClass.touched || registerFormSubmitted),
                  'is-valid': registerForm.controls.ageClass.valid
                }">
                  <option value="" selected disabled>Auswählen</option>
                  <option [value]="ageClass.value" *ngFor="let ageClass of ageClasses; let i = index">
                    {{ageClass.name}}
                  </option>
                </select>
                <label for="registerGender">Altersklasse</label>
                <div class="invalid-feedback" *ngIf="registerForm.controls.ageClass.hasError('required')">
                  Standard-Altersklasse angeben!
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="password" class="form-control" id="registerPassword" placeholder="Passwort"
                  formControlName="password" [ngClass]="{ 
                    'is-invalid': registerForm.controls.password.invalid && (registerForm.controls.password.touched || registerFormSubmitted),
                    'is-valid': registerForm.controls.password.valid && registerForm.controls.password.touched
                }">
                <label for="registerPassword">Passwort</label>
                <div class="invalid-feedback" *ngIf="registerForm.controls.password.errors?.minlength">
                  Min. 8 Zeichen!
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="password" class="form-control" id="repeatPassword" placeholder="Passwort"
                  formControlName="passwordConfirm" [ngClass]="{ 
                    'is-invalid': registerForm.controls.password.valid && (registerFormSubmitted|| registerForm.controls.passwordConfirm.touched) && (registerForm.errors?.passwordConfirm || registerForm.controls.passwordConfirm.invalid),
                    'is-valid': registerForm.controls.passwordConfirm.valid && registerForm.controls.passwordConfirm.touched
                }">
                <label for="repeatPassword">Passwort wiederholen</label>
                <div class="invalid-feedback" *ngIf="registerForm.errors?.passwordConfirm">
                  Passwörter sind nicht identisch!
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr>
              <legend>Spieler suchen</legend>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select class="form-select" id="registerFederation" formControlName="federation" [ngClass]="{
                  'is-invalid': registerForm.controls.federation.invalid && (userSearchInvalid || registerFormSubmitted),
                  'is-valid': registerForm.controls.federation.valid
                }">
                  <option value="" disabled selected>Auswählen</option>
                  <option value="{{federation.value}}" [disabled]="federation.disabled"
                    *ngFor="let federation of federations; let i = index">
                    {{federation.name}} <span *ngIf="federation.disabled">(nicht unterstützt)</span>
                  </option>
                </select>
                <label for="registerFederation">Verband</label>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-floating">
                <input type="text" id="searchFirstName" class="form-control" formControlName="searchFirstName"
                  placeholder="Vorname" [ngClass]="{
                    'is-invalid': userSearchInvalid
                  }">
                <label for="searchFirstName">Vorname</label>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-floating">
                <input type="text" id="searchLastName" class="form-control" formControlName="searchLastName"
                  placeholder="Nachname" [ngClass]="{
                    'is-invalid': userSearchInvalid
                  }">
                <label for="searchLastName">Nachname</label>
              </div>
            </div>
            <div class="col-lg-2">
              <button type="button" class="btn btn-primary h-100 w-100" (click)="searchUser()">
                <span class="spinner-border spinner-border-sm me-1" *ngIf="userSearchLoading"></span>
                <i class="fa fa-search me-1"></i>
                <span>Suchen</span>
              </button>
            </div>
            <div class="col-12 text-danger small" *ngIf="userSearchError">
              Server-Fehler! Suche fehlgeschlagen.
            </div>
            <div class="col-12 text-danger small"
              *ngIf="(registerForm.controls.dtbId.invalid || registerForm.controls.clubNumber.invalid) && registerFormSubmitted && users.length == 0">
              Suche zuerst deine Spielerdaten!
            </div>
            <div class="col-12 text-danger small"
              *ngIf="(registerForm.controls.dtbId.invalid || registerForm.controls.clubNumber.invalid) && registerFormSubmitted && users.length != 0">
              Wähle dein Spielerprofil aus!
            </div>
            <div class="col-12" *ngIf="userSearched && !userSelected">
              <div class="card">
                <h5 class="card-header">
                  Suchergebnisse
                </h5>
                <div class="card-body" *ngIf="users.length == 0">
                  Es konnten keine passenden Spieler gefunden werden. Bitte achte auf den Verband und die korrekte
                  Schreibweise deines Namens.
                  <br>
                  Alternativ kannst du es mit deiner DTB-ID probieren:
                  <br>
                  <div class="row g-1">
                    <div class="col-9">
                      <div class="form-floating">
                        <input type="number" class="form-control" id="searchId" formControlName="searchId"
                          placeholder="12345678" [ngClass]="{
                          'is-invalid': userSearchInvalid
                        }">
                        <label for="searchId">DTB-ID</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <button type="button" class="btn btn-primary h-100 w-100" (click)="searchUser()">
                        <i class="fa fa-search"></i>
                        <span class="ms-1 d-none d-md-inline">Suchen</span>
                      </button>
                    </div>
                  </div>
                </div>

                <ul class="list-group list-group-flush overflow-scroll" style="max-height: 45vh;"
                  *ngIf="users.length != 0">
                  <li class="list-group-item" *ngFor="let user of users; let i = index">
                    <i class="fa" [ngClass]=" {
                      'fa-male': user.gender == 1,
                      'fa-female': user.gender == 0
                    }"></i>
                    {{ user.firstName }} {{ user.lastName }} ({{ user.birthYear }})
                    <button type="buttonr" class="btn btn-outline-success float-end" (click)="selectUser(i)">
                      <i class="fa fa-user-check"></i>
                    </button>
                    <br>
                    {{ user.dtbId }}
                    <br>
                    {{ user.clubName }} ({{ user.clubNumber }})
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12" *ngIf="userSelected">
              <div class="card">
                <h5 class="card-header">
                  Spielerprofil
                </h5>
                <div class="card-body">
                  <input type="hidden" [ngModel]="selectedUser.dtbId" formControlName="dtbId">
                  <input type="hidden" [ngModel]="selectedUser.clubNumber" formControlName="clubNumber">
                  <i class="fa" [ngClass]=" {
                    'fa-male': selectedUser.gender == 1,
                    'fa-female': selectedUser.gender == 0
                  }"></i>
                  {{ selectedUser.firstName }} {{ selectedUser.lastName }} ({{ selectedUser.birthYear }})
                  <br>
                  {{ selectedUser.dtbId }}
                  <br>
                  {{ selectedUser.clubName }} ({{ selectedUser.clubNumber }})
                  <br>
                  Aktuell:
                  <span class="spinner-border spinner-border-sm" *ngIf="lkLoading"></span>
                  <span class="text-danger" *ngIf="lkError">LK nicht gefunden</span>
                  <span class="fw-bold" *ngIf="selectedUser.lk ">LK{{ selectedUser.lk | number:'1.1-1' }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 mb-2">
              <hr>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="datenschutz" name="datenschutz"
                  formControlName="datenschutz" required [ngClass]="{
                    'is-invalid': registerForm.controls.datenschutz.invalid && (registerForm.controls.datenschutz.dirty || registerFormSubmitted),
                    'is-valid': registerForm.controls.datenschutz.valid
                  }">
                <label class="form-check-label" for="datenschutz">Ich akzeptiere die <a href="/impressum#datenschutz"
                    target="_blank">Datenschutzerklärung</a></label>
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-lg btn-success w-100" [disabled]="registerLoading">
                <span class="spinner-border spinner-border-sm me-1" *ngIf="registerLoading"></span>
                <i class="fa fa-check me-1"></i>Registrieren
              </button>
              <div class="alert alert-danger my-2" *ngIf="registerError">
                <i class="fa fa-exclamation-circle me-1"></i>Registrierung konnte nicht abgeschlossen werden
              </div>
            </div>
          </div>
        </form>
        <div class="row g-2" *ngIf="registered">
          <div class="col-12">
            <p>
              Willkommen bei Inside In, {{ registerUser.firstName }}!
              <br>
              Du hast deinen Account erfolgreich erstellt. Jetzt musst du ihn nur noch aktivieren.
            </p>
            <p>
              Hierzu wurde dir eine E-Mail an <span class="fw-bold">{{ registerUser.email }}</span> geschickt.
              <br>
              Öffne die E-Mail und klicke <b>binnen 48 Stunden</b> auf den Link um deinen Account zu aktivieren.
              Überprüfe auch deinen Spam-Ordner.
            </p>
            <div class="alert alert-danger my-2" role="alert" *ngIf="emailError">
              <i class="fa fa-times-circle me-1"></i>Es konnte keine Aktivierungs-E-Mail an <span
                class="fw-bold">{{ registerUser.email }}</span> versendet werden!
              <br>
              Hast du eine falsche E-Mail-Adresse angegeben? <a href="/" class="alert-link">Registriere</a> dich bitte
              erneut
            </div>
            <div class="alert alert-success my-2" role="alert" *ngIf="emailSentAgain">
              <i class="fa fa-check-circle me-1"></i>E-Mail an <span class="fw-bold">{{ registerUser.email }}</span>
              versendet
            </div>
            <p>
              Du hast keine E-Mail erhalten?
              <br>
              <button class="btn btn-primary" (click)="sendActivationMail(true)" [disabled]="emailSending">
                <span class="spinner-border spinner-border-sm me-1" *ngIf="emailSending"></span>
                <i class="fa fa-envelope me-1"></i>Erneut senden
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <i class="fa fa-times-circle me-1"></i>Schließen
        </button>
      </div>
    </div>
  </div>
</div>
