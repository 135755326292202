import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Result, Match } from 'src/app/models/result';
import { WinTable, Opponent } from 'src/app/models/winTable';
import { Club } from 'src/app/models/club';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';
import * as Chart from 'chart.js';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  // User
  user: User | null = new User;

  // Chart
  chart: any;
  data: [] = [];
  labels: [] = [];

  // Register
  registerForm: FormGroup;
  registerFormSubmitted = false;
  registerSubscription: any;
  usernameSuggest = '';
  registered = false;
  registerLoading = false;
  registerError: boolean = false;

  // User search
  users: User[] = [];
  selectedUser = new User;
  userSearchLoading = false;
  userSearched = false;
  userSearchError = false;
  userSearchInvalid = false;
  userSelected = false;
  lkLoading = false;
  lkError: boolean = false;

  // Register User
  registerUser = new User;

  // Activation
  emailSending = false;
  emailError = false;
  emailSentAgain = false;
  emailSent = false;

  // Login
  loginForm: FormGroup;
  loginLoading: boolean = false;
  loginError: boolean = false;
  loginFormError: boolean = false;
  loginClubError: boolean = false;
  loginPasswordError: boolean = false;
  loginUserError: boolean = false;
  loginActivationError: boolean = false;
  loggedIn: boolean = false;

  // Calculator
  result = new Result();
  calcForm: FormGroup;
  calcFormSubmitted = false;
  calcSubscription: any;
  resultCalculated = false;

  // Win Table
  winTable = new WinTable();
  winTableForm: FormGroup;
  winTableCalculated = false;

  ageClasses = [
    { value: 'u10m', name: 'U10 m', factor: 0.25 },
    { value: 'u10w', name: 'U10 w', factor: 0.30 },
    { value: 'u11m', name: 'U11 m', factor: 0.30 },
    { value: 'u11w', name: 'U11 w', factor: 0.40 },
    { value: 'u12m', name: 'U12 m', factor: 0.40 },
    { value: 'u12w', name: 'U12 w', factor: 0.50 },
    { value: 'u13m', name: 'U13 m', factor: 0.50 },
    { value: 'u13w', name: 'U13 w', factor: 0.60 },
    { value: 'u14m', name: 'U14 m', factor: 0.60 },
    { value: 'u14w', name: 'U14 w', factor: 0.70 },
    { value: 'u15m', name: 'U15 m', factor: 0.70 },
    { value: 'u15w', name: 'U15 w', factor: 0.80 },
    { value: 'u16m', name: 'U16 m', factor: 0.80 },
    { value: 'u16w', name: 'U16 w', factor: 0.90 },
    { value: 'u17m', name: 'U17 m', factor: 0.90 },
    { value: 'u17w', name: 'U17 w', factor: 1 },
    { value: 'u18', name: 'U18', factor: 1 },
    { value: 'u21', name: 'U21', factor: 1 },
    { value: 'active', name: 'Aktive', factor: 1 },
    { value: 'o30', name: 'Ü30', factor: 0.9 },
    { value: 'o35', name: 'Ü35', factor: 0.85 },
    { value: 'o40', name: 'Ü40', factor: 0.8 },
    { value: 'o45', name: 'Ü45', factor: 0.75 },
    { value: 'o50', name: 'Ü50', factor: 0.7 },
    { value: 'o55', name: 'Ü55', factor: 0.65 },
    { value: 'o60', name: 'Ü60', factor: 0.6 },
    { value: 'o65', name: 'Ü65', factor: 0.55 },
    { value: 'o70', name: 'Ü70', factor: 0.5 },
    { value: 'o75', name: 'Ü75', factor: 0.45 },
    { value: 'o80', name: 'Ü80', factor: 0.4 },
    { value: 'o85', name: 'Ü85', factor: 0.35 },
    { value: 'o90', name: 'Ü90', factor: 0.3 }
  ];

  federations = [
    { value: 'BAD', name: 'Baden', disabled: false },
    { value: 'BTV', name: 'Bayern', disabled: true },
    { value: 'TVBB', name: 'Berlin-Brandenburg', disabled: false },
    { value: 'HAM', name: 'Hamburg', disabled: false },
    { value: 'HTV', name: 'Hessen', disabled: false },
    { value: 'TMV', name: 'Mecklenburg-Vorpommern', disabled: false },
    { value: 'TVM', name: 'Mittelrhein', disabled: false },
    { value: 'TVN', name: 'Niederrhein', disabled: false },
    { value: 'TNB', name: 'Niedersachsen-Bremen', disabled: false },
    { value: 'RPF', name: 'Rheinland-Pfalz', disabled: true },
    { value: 'STB', name: 'Saarland', disabled: false },
    { value: 'STV', name: 'Sachsen', disabled: false },
    { value: 'TSA', name: 'Sachsen-Anhalt', disabled: false },
    { value: 'SLH', name: 'Schleswig-Holstein', disabled: false },
    { value: 'TTV', name: 'Thüringen', disabled: false },
    { value: 'WTV', name: 'Westfalen', disabled: false },
    { value: 'WTB', name: 'Württemberg', disabled: false }
  ];


  constructor(
    private fb: FormBuilder,
    private authservice: AuthService,
    private api: ApiService
  ) {
    // Auth Subscription
    this.authservice.user.subscribe(
      user => {
        this.user = user;
        console.log(this.user)
        if (this.user) {
          this.initialize();
        }
      }
    );

    this.calcForm = this.fb.group({
      myLk: [null, this.lkValidators],
      matches: this.fb.array([this.createMatch()])
    });

    this.winTableForm = this.fb.group({
      myLk: [null, this.lkValidators],
      ageClass: [null, Validators.required],
      teamGame: ['']
    });

    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern('^[a-z0-9_.]*$')],
        [this.checkUsername.bind(this)]],
      ageClass: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(8)]],
      federation: ['', [Validators.required]],
      searchFirstName: [''],
      searchLastName: [''],
      searchId: ['', [Validators.min(10000000), Validators.max(29999999)]],
      dtbId: ['', [Validators.required, Validators.min(10000000), Validators.max(29999999)]],
      clubNumber: ['', [Validators.required, Validators.min(1000), Validators.max(999999)]],
      datenschutz: ['', Validators.required]
    });
    this.registerForm.setValidators(this.passwordConfirm);

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  get matches(): any {
    return this.calcForm.get('matches') as FormArray;
  }

  private lkValidators = [
    Validators.required,
    Validators.min(1),
    Validators.max(25)
  ];

  private passwordConfirm(c: any): { [key: string]: boolean } | null {
    if (c.controls.password.value !== c.controls.passwordConfirm.value) {
      return { passwordConfirm: true };
    }
    return null;
  }

  ngOnInit(): void {
    /* Subscribe to CalcForm Changes */
    console.log(this.calcForm);
    this.calcSubscription = this.calcForm.valueChanges.subscribe(
      () => {
        this.calcFormSubmitted = false;
        this.resultCalculated = false;
      }
    );

    this.registerSubscription = this.registerForm.valueChanges.subscribe(
      () => {
        this.registerFormSubmitted = false;
        this.usernameSuggest = this.registerForm.controls.email.value.split('@')[0];
      }
    );

    this.winTableForm.valueChanges.subscribe(
      () => {
        this.submitWinTableForm();
      }
    )

  }

  initialize(): void {
    this.getProfile();
    this.getLks();
  }

  setForms(): void {
    this.calcForm.controls.myLk.setValue(this.user?.lk);
    const matches = this.calcForm.controls.matches as FormArray
    matches.controls.forEach((c: any) => {
      c.controls.ageClass.setValue(this.user?.ageClass);
    })

    this.winTableForm.controls.myLk.setValue(this.user?.lk);
    this.winTableForm.controls.ageClass.setValue(this.user?.ageClass);
  }

  getProfile(): void {
    this.api.getProfile().subscribe(
      (res: any) => {
        this.user = res.body;
        console.log(this.user);
      },
      (err: HttpErrorResponse) => {
        console.log(err)
      }
    );
  }

  getLks(): void {
    this.api.getLks().subscribe(
      (res: any) => {
        this.data = res.body.map(
          (e: any) => {
            return e.lk
          })
        this.labels = res.body.map(
          (e: any) => {
            return e.date
          })
        this.setForms();
        this.getChart();
      },
      (err: HttpErrorResponse) => {
        console.log(err)
      }
    );
  }

  getChart(): void {
    Chart.defaults.global.defaultFontFamily = 'Varela Round';
    const chart: any = document.getElementById('chart');
    const ctx = chart.getContext('2d');

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          fill: false,
          borderColor: '#78E8FC'
        }]
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        scales: {
          xAxes: [{
            display: true,
            type: 'time',
            time: {
              unit: 'week',
              tooltipFormat: 'DD.MM.YY',
              displayFormats: {
                week: 'DD.MM.YY'
              }
            },
            ticks: {
              source: 'data'
            },
            gridLines: {
              color: '#666',
              borderDash: [4]
            },
          }
          ],
          yAxes: [{
            ticks: {
              reverse: true,
              min: 1,
              max: 25
            },
            gridLines: {
              color: '#666',
              borderDash: [4]
            },
          }]
        }
      }
    });
  }

  changeData(): void {
    /* if (this.data === this.data1) {
      this.data = this.data2;
    } else {
      this.data = this.data1;
    } */

    /* this.chart.data.datasets[0].data = this.data;
    this.chart.update(); */

    this.getChart();
  }

  logout(all?: boolean): void {
    this.authservice.logout(all).subscribe();
    this.resetCalcForm();
    this.winTableForm.reset();
  }

  checkUsername(control: AbstractControl): any {
    if (control.value) {
      return this.authservice.checkUsername(control.value).pipe(
        map((res: any) => {
          if (res.taken === true) {
            return { userNameTaken: true };
          } else {
            return null;
          }
        }) // use observables, don't convert to promises
      );
    }
    return null; // gotta return an observable for async
  }

  searchUser(): void {
    this.userSearchInvalid = false;
    if (
      this.registerForm.controls.federation.invalid ||
      ((this.registerForm.controls.searchId.invalid ||
        this.registerForm.controls.searchId.value == '') &&
        (this.registerForm.controls.searchFirstName.value == '' ||
          this.registerForm.controls.searchLastName.value == ''))
    ) {
      console.log('invalid Search')
      this.userSearchInvalid = true;
      return
    }
    this.users = [];
    this.userSearched = false;
    this.userSearchLoading = true;
    this.userSearchError = false;
    this.userSelected = false;
    this.api.searchUser(
      this.registerForm.controls.federation.value,
      this.registerForm.controls.searchFirstName.value,
      this.registerForm.controls.searchLastName.value,
      this.registerForm.controls.searchId.value
    ).subscribe(
      (res: any) => {
        this.users = res.body;
        this.userSearchLoading = false;
        this.userSearched = true;
      },
      (err: HttpErrorResponse) => {
        this.userSearchLoading = false;
        if (err.status == 404) {
          this.userSearched = true;
        } else {
          this.userSearchError = true;
        }
      }
    );
  }

  selectUser(i: number): void {
    this.selectedUser = this.users[i];
    this.userSelected = true;
    this.lkLoading = true;
    this.lkError = false;
    this.api.getLk(
      this.selectedUser.federation,
      this.selectedUser.clubNumber,
      this.selectedUser.dtbId,
      this.selectedUser.gender
    ).subscribe(
      (res: any) => {
        this.selectedUser.lk = res.body
        this.lkLoading = false;
      },
      (err: HttpErrorResponse) => {
        this.lkLoading = false;
        this.lkError = true;
      }
    )
  }

  submitRegisterForm(): void {
    console.log(this.registerForm);
    this.registerFormSubmitted = true;
    if (this.registerForm.invalid) {
      return
    }
    this.registerLoading = true;
    this.registerError = false;
    this.authservice.register(this.registerForm.value).subscribe(
      (res: any) => {
        this.registerLoading = false;
        this.registerError = false;
        this.registered = true;
        this.registerUser.userId = res.body.userId;
        this.registerUser.email = this.registerForm.controls.email.value;
        this.registerUser.firstName = this.selectedUser.firstName;
        this.registerUser.lk = this.selectedUser.lk;
        this.sendActivationMail();
      },
      (err: HttpErrorResponse) => {
        this.registerLoading = false;
        this.registerError = true;
        console.log(err);
      }
    );
  }

  sendActivationMail(again?: boolean): void {
    this.emailSending = true;
    this.authservice.sendActivationMail(this.registerUser.userId).subscribe(
      (res: any) => {
        this.emailSending = false;
        this.emailError = false;
        if (res.status === 200) {
          if (again === true) {
            this.emailSentAgain = true;
          } else {
            this.emailSent = true;
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.emailSending = false;
        this.emailError = true;
      }
    );
  }

  submitLoginForm(): void {
    this.loginLoading = true;
    this.loginError = false;
    this.loginFormError = false;
    this.loginClubError = false;
    this.loginPasswordError = false;
    this.loginUserError = false;
    this.loginActivationError = false;
    this.loggedIn = false;

    this.authservice.login(this.loginForm.value).subscribe(
      (res: any) => {
        this.loggedIn = true;
        this.loginLoading = false;
        this.authservice.setAuth(res.body.accessToken);
      },
      (err: HttpErrorResponse) => {
        this.loggedIn = false;
        this.loginLoading = false;
        if (err.status === 404) {
          this.loginUserError = true;
        } else if (err.status === 400 && err.error.message === 'No username or password') {
          this.loginFormError = true;
        } else if (err.status === 400 && err.error.message === 'Wrong password') {
          this.loginPasswordError = true;
        } else if (err.status === 400 && err.error.message === 'User not activated') {
          this.loginActivationError = true;
        } else {
          this.loginError = true;
        }
      }
    );
  }

  createMatch(): any {
    const ageClass = !this.user?.ageClass ? null : this.user?.ageClass;
    return this.fb.group({
      matchType: ['singles', Validators.required],
      ageClass: [ageClass, Validators.required],
      partnerLk: [''],
      opponentLk1: ['', this.lkValidators],
      opponentLk2: [''],
      teamGame: false
    });
  }

  addMatch(): void {
    this.matches.push(this.createMatch());
  }

  removeMatch(i: number): void {
    if (i > 0) {
      this.matches.removeAt(i);
    }
  }

  switchMatchType(i: number): void {
    if (this.matches.controls[i].controls.matchType.value === 'doubles') {
      this.matches.controls[i].controls.partnerLk.setValidators(this.lkValidators);
      this.matches.controls[i].controls.opponentLk2.setValidators(this.lkValidators);
    }
    else {
      this.matches.controls[i].controls.partnerLk.clearValidators();
      this.matches.controls[i].controls.opponentLk2.clearValidators();
    }
  }

  resetMyLk(): void {
    this.calcForm.controls.myLk.setValue(null);
  }

  resetCalcForm(): void {
    this.calcForm.reset();
    if (this.user?.lk) {
      this.calcForm.controls.myLk.setValue(this.user?.lk);
    }
    this.matches.clear();

    this.addMatch();
  }

  submitCalcForm(): void {
    this.calcFormSubmitted = true;

    if (this.calcForm.valid) {
      this.resultCalculated = true;
      this.getResult(this.calcForm.value);
    }
  }

  getResult(calc: any): void {

    const result = new Result();
    const matches: Array<Match> = [];

    /* Aktuelle LK */
    const myLk = calc.myLk;
    result.currentLk = myLk;

    /* Hürde */
    // const hurdle = 50 + 45.07 * ((25 - myLk) / Math.sqrt(myLk));

    /* Neue LK initieren */
    let newLk = myLk;

    /* Verbesserung initieren */
    let totalImprovement = 0;

    for (const match of calc.matches) {
      const matchResult = new Match();
      /* Altersklassenfaktor */
      let ageClassFactor = this.ageClasses.find(({ value }) => value === match.ageClass)?.factor;
      if (ageClassFactor === undefined) {
        ageClassFactor = 1;
      }

      /* Gegner & Partner LK */
      let opponentLk1 = match.opponentLk1;
      let partnerLk = match.partnerLk;
      let opponentLk2 = match.opponentLk2;

      opponentLk1 = this.getRealLk(opponentLk1);
      partnerLk = this.getRealLk(partnerLk);
      opponentLk2 = this.getRealLk(opponentLk2);

      if (Number.isInteger(newLk) === false) {
        const split = newLk.toString().split('.');
        newLk = parseFloat(split[0] + '.' + split[1][0]);
      }

      /* Differenz initieren */
      let diff = 0;

      /* Hürde initieren */
      let hurdle = 0;

      /* Matchtyp: Einzel-Faktor 100% */
      let matchTypeFactor = 1;

      if (match.matchType === 'singles') {
        /* Differenz */
        diff = myLk - opponentLk1;
        /* Matchtyp Singles */
        matchResult.matchType = 'singles';
        /* Hürde */
        hurdle = 50 + 45.07 * ((25 - myLk) / Math.sqrt(myLk));
      } else {
        /* Differenz der Mittelwerte beider Paare */
        diff = ((myLk + partnerLk) / 2) - ((opponentLk1 + opponentLk2) / 2);
        /* Matchtyp Doubles */
        matchResult.matchType = 'doubles';
        /* Matchtyp: Doppel-Faktor 50% */
        matchTypeFactor = 0.5;
        /* Hürde */
        hurdle = 50 + 45.07 * ((25 - (myLk + partnerLk) / 2) / Math.sqrt((myLk + partnerLk) / 2));
      }

      /* Punkte zu Differenz */
      let points = 0;
      if (diff <= -4) {
        points = 10;
      } else if (diff > -4 && diff <= 0) {
        points = (-1.25 * Math.pow(diff, 3)) - (7.5 * Math.pow(diff, 2)) + 50;
      } else if (diff >= 0 && diff < 4) {
        points = (-1.875 * Math.pow(diff, 3)) + (11.25 * Math.pow(diff, 2)) + 50;
      }
      else if (diff >= 4) {
        points = 110;
      }

      /* Mannschaftsspiel-Faktor */
      const teamGame = match.teamGame === true ? 1.1 : 1;
      matchResult.teamGame = match.teamGame;

      /* Verbesserung (mit Matchtyp-Faktor) */
      const improvement = (points / hurdle) * ageClassFactor * teamGame * matchTypeFactor;
      matchResult.improvement = improvement;
      newLk -= improvement;
      totalImprovement += improvement;

      matches.push(matchResult);

    }

    /* LK-Begleitwert wird vollen Dezimalstellen */
    result.sideLk = newLk;

    /* Restliche Dezimalstellen abschneiden */
    newLk = this.getRealLk(newLk);

    result.newLk = newLk;
    result.totalImprovement = totalImprovement;
    result.matches = matches;

    this.result = result;
  }

  resetWinTableLk(): void {
    this.winTableForm.controls.myLk.setValue(null);
    this.winTableCalculated = false;
  }

  submitWinTableForm(): void {

    if (this.winTableForm.valid) {
      this.winTableCalculated = true;
      this.getWinTable(this.winTableForm.value);
    } else {
      this.winTableCalculated = false;
    }
  }

  getWinTable(calc: any): void {
    const winTable = new WinTable();
    const opponents: Opponent[] = [];

    /* Aktuelle LK */
    const myLk = calc.myLk;

    /* Hürde */
    const hurdle = 50 + 45.07 * ((25 - myLk) / Math.sqrt(myLk));

    /* Altersklassenfaktor */
    let ageClassFactor = this.ageClasses.find(({ value }) => value === calc.ageClass)?.factor;

    /* Mannschaftsspiel-Faktor */
    const teamGame = calc.teamGame === true ? 1.1 : 1;

    /* Gegner holen */
    const opponentArray = [];
    let lk = myLk - 4;
    let breakLoop = false;
    while (lk <= (myLk + 4) && breakLoop === false) {
      if (lk >= 25) {
        lk = 25;
        breakLoop = true;
      }

      if (lk < 1 && lk > 0) {
        opponentArray.push(1);
      } else if (lk > 0) {
        opponentArray.push(lk);
      }

      lk += 1;
    }

    /* Verbesserungen berechnen */
    opponentArray.forEach((opponentLk) => {

      const opponent = new Opponent();
      opponent.opponentLk = opponentLk;

      /* Differenz */
      const diff = myLk - opponentLk;
      /* Punkte zu Differenz */
      let points = 0;
      if (diff <= -4) {
        points = 10;
      } else if (diff > -4 && diff <= 0) {
        points = (-1.25 * Math.pow(diff, 3)) - (7.5 * Math.pow(diff, 2)) + 50;
      } else if (diff >= 0 && diff < 4) {
        points = (-1.875 * Math.pow(diff, 3)) + (11.25 * Math.pow(diff, 2)) + 50;
      }
      else if (diff >= 4) {
        points = 110;
      }

      /* Altersklassenfaktor undefined */
      ageClassFactor = ageClassFactor === undefined ? 1 : ageClassFactor;

      /* Verbesserung */
      opponent.improvement = (points / hurdle) * ageClassFactor * teamGame;

      /* Neue LK */
      let newLk = myLk - opponent.improvement;

      /* Restliche Dezimalstellen abschneiden */
      newLk = this.getRealLk(newLk);

      opponent.newLk = newLk;

      opponents.push(opponent);
    });

    winTable.hurdle = hurdle;
    winTable.ageClassFactor = ageClassFactor;
    winTable.opponents = opponents;
    this.winTable = winTable;
  }

  getRealLk(lk: number): any {
    let newLk = lk;
    if (newLk % 1 !== 0 && newLk !== undefined) {
      const split = newLk.toString().split('.');
      newLk = parseFloat(split[0] + '.' + split[1][0]);
    }

    return newLk;
  }

}
