import { Component, OnInit } from '@angular/core';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public version = packageInfo.version;
  year: number = Date.now();

  useSystemDarkMode = false;
  availableSystemDarkMode = false;

  constructor() { }

  ngOnInit(): void {
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this.availableSystemDarkMode = true;

      if (localStorage.getItem('useSystemDarkMode') === 'false') {
        this.useSystemDarkMode = false;
      } else {
        this.useSystemDarkMode = true;
      }
    } else {
      this.useSystemDarkMode = false;
      this.availableSystemDarkMode = false;
    }

    this.setSystemDarkMode(this.useSystemDarkMode);
  }

  setSystemDarkMode(useSystemDarkMode: boolean): void {
    localStorage.setItem('useSystemDarkMode', String(useSystemDarkMode));
  }

  changeSystemDarkMode(event: any): void {
    if (event.target.checked === true) {
      this.useSystemDarkMode = true;
      alert('Die Seite wird aktualisiert, damit deine System-Dark-Mode-Einstellung verwendet wird.');
    } else {
      this.useSystemDarkMode = false;
      alert('Die Seite wird aktualisiert, damit deine System-Dark-Mode-Einstellung nicht verwendet wird.');
    }
    this.setSystemDarkMode(this.useSystemDarkMode);
    window.location.reload();
  }

}

