import { ApplicationRef, Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'inside-in';

  constructor(
    private update: SwUpdate,
    private appRef: ApplicationRef
  ) {
    this.updateClient();
    this.checkUpdate();
  }

  updateClient(): void {
    if (!this.update.isEnabled) {
      return;
    }
    this.update.available.subscribe(event => {
      if (confirm('Neue Version verfügbar!')) { 
        this.update.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  checkUpdate(): void {
    this.appRef.isStable.subscribe(isStable => {
      if (isStable) {
        const stableInterval = interval(4*60*60*1000);

        stableInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log('checked'));
        })
      }
    })
  }
  
}
