import { HttpRequest, HttpHandler, HttpInterceptor, HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

import { Injectable } from '@angular/core';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refreshTokenInProgress: boolean | undefined;
    tokenRefreshedSubject = new Subject();
    tokenRefreshed = this.tokenRefreshedSubject.asObservable();

    constructor(
        private router: Router,
        private authservice: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addAuthHeader(req);
        
        return next.handle(req).pipe(catchError(err => {
            return this.handleResponseError(err, req, next);
        }));
    }

    addAuthHeader(req: HttpRequest<any>): any {
        let token: string | undefined;
        this.authservice.user.subscribe(
            user => {
                if (user) {
                    token = user.token;
                }
            }
        );
        if (token) {
            return req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return req;
    }

    handleResponseError(err: any, req?: any, next?: any): Observable<any> {
        if (err.status === 401) {
            return this.refreshToken().pipe(
                switchMap((res: any) => {
                    this.refreshTokenInProgress = false;
                    this.authservice.setAuth(res.body.accessToken);
                    req = this.addAuthHeader(req);
                    return next.handle(req);
                }),
                catchError((e: any) => {
                    if (e.status !== 401) {
                        return this.handleResponseError(e);
                    } else {
                        this.refreshTokenInProgress = false;
                        return this.authservice.logout();
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }

    refreshToken(): Observable<any> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;

            return this.authservice.refresh().pipe(
                tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSubject.next();
                }),
                catchError((err) => {
                    this.refreshTokenInProgress = false;
                    return this.authservice.logout();
                })
            );
        } else {
            return new Observable(observer => {
                this.tokenRefreshed.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        }
    }

}

export const AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
